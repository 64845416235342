@font-face {
  font-family: 'Sun Life New Display Bold';
  src: url('/static/fonts/SunLifeNewDisplay-Bold.woff') format('woff');
  src: url('/static/fonts/SunLifeNewDisplay-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Sun Life New Display Regular';
  src: url('/static/fonts/SunLifeNewDisplay-Regular.woff') format('woff');
  src: url('/static/fonts/SunLifeNewDisplay-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Sun Life New Text Bold';
  src: url('/static/fonts/SunLifeNewText-Bold.woff') format('woff');
  src: url('/static/fonts/SunLifeNewText-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Sun Life New Text Bold Italic';
  src: url('/static/fonts/SunLifeNewText-BoldItalic.woff') format('woff');
  src: url('/static/fonts/SunLifeNewText-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Sun Life New Text Italic';
  src: url('/static/fonts/SunLifeNewText-Italic.woff') format('woff');
  src: url('/static/fonts/SunLifeNewText-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Sun Life New Text Regular';
  src: url('/static/fonts/SunLifeNewText-Regular.woff') format('woff');
  src: url('/static/fonts/SunLifeNewText-Regular.woff2') format('woff2');
}
